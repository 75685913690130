<template>
  <div class="teacher_management_home_page">
    <myTable title="教师管理" :columns="columns" :list="list">
      <template slot="top_block">
        <div class="code_block">
          <el-button @click="addTeacher" class="table_top_btn_default"
            >添加教师 +
          </el-button>
        </div>
      </template>
      <template slot="btns" slot-scope="item">
        <div class="table_btns">
          <div class="table_btn" @click="handleEdit(item.data.row, 'view')">
            查看
          </div>
          <div class="table_btn" @click="handleEdit(item.data.row, 'edit')">
            编辑
          </div>
          <div class="table_btn" @click="handleDel(item.data.row)">删除</div>
        </div>
      </template>
    </myTable>
    <comPagination :params="params" @changePage="changePage"></comPagination>
    <comdialog
      ref="comdialog"
      :params.sync="teacherInfo"
      :edit="alertStatus"
      :title="alertStatus === 'add' ? '新增教师' : '编辑教师'"
      @onSubmit="onSubmit"
      @close="comdialogClose"
    ></comdialog>
  </div>
</template>

<script>
import myTable from "@/components/admin/table.vue";
import comdialog from "@/components/admin/editTeacherDialog.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "headerSchool",
  components: {
    comdialog,
    comPagination,
    myTable,
  },
  data() {
    return {
      //班级列表
      columns: [
        {
          prop: "id",
          label: "编号",
        },
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "sex",
          label: "性别",
        },
        {
          prop: "phone",
          label: "手机号",
        },
        {
          prop: "level",
          label: "教师等级",
        },
      ],
      list: [],
      //分页
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
      teacherInfo: {
        name: "",
        sex: 1,
        natives: "",
        graduateSchool: "",
        education: "",
        idCard: "",
        teacherCertificateNumber: "",
        certificateTime: "",
        birthday: "",
        gradeIds: "",
        gradeIdsValue: "",
        photo: "",
        phone: "",
        password: "",
        againPassword: "",
        email: "",
      },
      // 弹窗状态  新增/编辑/查看
      alertStatus: "",
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //初始化
    init() {
      this.getTeacherInfo();
    },
    getTeacherInfo() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.admin.findTeacherBySchoolId(this.params, data).then((res) => {
        this.params.total = res.data.total;
        this.list = res.data.list.map((item) => {
          let data = {
            ...item.atteTeacher,
            avatar: item.avatar,
            email: item.email,
            level: item.level,
            password: item.password || "",
            username: item.username || "",
            sex: item.atteTeacher.sex === "1" ? "男" : "女",
          };
          return data;
        });
      });
    },
    getTeacherGrades(val) {
      let data = {
        teacherId: val,
      };
      this.api.admin.findGradesOfid(data).then((res) => {
        const grades = res.data;
        const that = this;
        let gradeIds = [];
        let gradeIdsValue = [];
        for (var x in grades) {
          gradeIds.push(grades[x].id);
          gradeIdsValue.push(grades[x].name);
        }
        that.teacherInfo.gradeIds = gradeIds;
        that.teacherInfo.gradeIdsValue = gradeIdsValue;
        console.log(that.teacherInfo);
      });
    },
    onSubmit(val) {
      // 新增
      if (this.alertStatus === "add") {
        console.log(123);
        let data = {
          atteTeacher: {
            name: val.name,
            sex: val.sex,
            natives: val.natives,
            graduateSchool: val.graduateSchool,
            education: val.education,
            idCard: val.idCard,
            teacherCertificateNumber: val.teacherCertificateNumber,
            certificateTime: val.certificateTime,
            birthday: val.birthday,
            gradeIds: val.gradeIds.join(","),
            photo: val.photo.split(".com")[1],
          },
          username: val.phone,
          email: val.email,
          password: val.password,
        };
        this.api.admin
          .atteTeacherAdd(this.$store.state.userInfo.schoolId, data)
          .then((res) => {
            if (res.flag) {
              this.$refs.comdialog.isClose();
              this.$notice({
                type: "success",
                message: res.message,
              }).isShow();
            } else {
              this.$notice({
                message: res.message,
              }).isShow();
            }
          });
      } else {
        //提交编辑

        let data = {
          atteTeacher: {
            id: val.id,
            userId: val.userId,
            name: val.name,
            sex: val.sex,
            natives: val.natives,
            graduateSchool: val.graduateSchool,
            education: val.education,
            idCard: val.idCard,
            teacherCertificateNumber: val.teacherCertificateNumber,
            certificateTime: val.certificateTime,
            birthday: val.birthday,
            gradeIds: val.gradeIds.join(","),
            photo: val.photo.split("com")[1],
          },
          username: val.phone,
          email: val.email,
          password: val.password,
        };

        this.api.admin.upadetTeacher(data).then((res) => {
          if (res.flag) {
            this.$refs.comdialog.isClose();
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          } else {
            this.$notice({
              message: res.message,
            }).isShow();
          }
          this.getTeacherInfo();
        });
      }
    },
    //打开编辑弹窗
    addTeacher() {
      this.$refs.comdialog.isOpen();
      this.alertStatus = "add";
      this.teacherInfo.gradeIds = "";
    },

    handleEdit(val, type) {
      // const that = this
      if (type === "view") {
        this.$router.push({
          path: "/admin/teacherHome",
          query: { ...val },
        });
      } else if (type === "edit") {
        //回显
        this.alertStatus = type;
        this.$refs.comdialog.isOpen();
        console.log(val);
        this.teacherInfo = {
          id: val.id,
          name: val.name,
          sex: val.sex === "男" ? 1 : 0,
          natives: val.natives,
          graduateSchool: val.graduateSchool,
          education: val.education,
          idCard: val.idCard,
          teacherCertificateNumber: val.teacherCertificateNumber,
          certificateTime: val.certificateTime,
          birthday: val.birthday,
          photo: val.photo
            ? "https://footballcn.oss-cn-beijing.aliyuncs.com/" + val.photo
            : "",
          phone: val.phone,
          password: val.password,
          againPassword: val.againPassword,
          email: val.email,
          gradeIds: val.gradeIds.split(","),
          gradeIdsValue: this.teacherInfo.gradeIdsValue,
          username: val.username,
          userId: val.userId,
        };
        // 获取老师所带班级
        this.getTeacherGrades(val.id);
      }
    },
    //关闭编辑弹窗
    comdialogClose() {
      this.teacherInfo = {
        name: "",
        sex: 1,
        natives: "",
        graduateSchool: "",
        education: "",
        idCard: "",
        teacherCertificateNumber: "",
        certificateTime: "",
        birthday: "",
        gradeIds: "",
        photo: "",
        phone: "",
        password: "",
        againPassword: "",
        email: "",
        gradeIdsValue: [],
      };
    },
    // 删除教师
    handleDel(val) {
      let deleMessage = '此操作将删除该教师, 是否继续?';
      if(val.integral){
        deleMessage = '删除该教师将影响园所的积分';
      }
      this.$confirm(deleMessage, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            teacherId: val.id,
            userId: val.userId,
          };
          this.api.admin.atteTeacherDel(data).then((res) => {
            if (res.flag) {
              this.$notice({
                type: "success",
                message: "删除成功!",
              }).isShow();
            } else {
              this.$notice({
                type: "warn",
                message: "删除失败!",
              }).isShow();
            }
            this.getTeacherInfo();
          });
        })
        .catch(() => {
          this.$notice({
            type: "warn",
            message: "已取消删除",
          }).isShow();
          this.getTeacherInfo();
        });
    },
    //分页 todo 待测试
    changePage(val) {
      console.log(val);
      this.params.page = val;
      this.getTeacherInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher_management_home_page {
}
</style>
<style lang="scss">