<template>
  <div class="table_page">
    <div class="table_top">
      <div class="title">{{ title }}</div>
        <slot name="top_block"></slot>
      </div>
      <el-table header-align="center" stripe header-cell-class-name="header_row_style" :data="list" style="width: 100%">
        
        <el-table-column :align="item.align || 'center'" :show-overflow-tooltip="true" v-for="item in columns" :key="item.prop" :prop="item.prop" :width="item.width" :label="item.label">
        </el-table-column>


        <el-table-column v-if="handle" align="center" label="操作">
          <template slot-scope="scope">
            <slot name="btns" :data="scope">
              
            </slot>
          </template>
        </el-table-column>
      </el-table>
    </div>
</template>

<script>
  export default {
    name: 'tablePage',
    components: {},
    props: {
      list: {
        type: Array,
        default: () => {
          return []
        },
      },
      columns: {
        type: Array,
        default: () => {
          return []
        },
      },
      handle: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {}
    },
    created() {
      console.log(this.list);
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .table_page {
    .table_top {
      @include flex(row, space-between, center);
      margin-bottom: 30px;

      .title {
        font-size: 34px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #cc1a30;
        // margin-bottom: 30px;
      }
    }
  }
</style>
<style lang="scss">
  // .header_row_style {
  //   color: #fff;
  //   background: #cc1a30 !important;
  //   font-size: 24px;
  //   font-family: Alibaba PuHuiTi;
  //   font-weight: bold;
  //   color: #ffffff;
  // }

  .el-table .cell {
    line-height: normal !important;
  }

  .table_flex {
    position: relative;
    @include flex();
  }

  .el-table td {
    border-bottom: 1px solid #cc1a30;
  }

  .el-table tr:last-child td {
    border-bottom: solid 4px #cb1b31 !important;
  }

  .table_btns {
    @include flex();
  }

  
</style>